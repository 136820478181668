.page {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.checkoutHeader {
  display: flex;
  justify-content: center;
}
.checkoutHeader h1 {
  max-width: 1200px;
  display: flex;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2.5rem;
  font-weight: 550;
  font-size: clamp(1.2rem, 6vw, 1.9rem);
}

.mainContent {
  display: flex;
  max-width: 1200px;
  margin: auto;
  gap: 11rem;
}

.pageItem {
  width: 50%;
}

.pageCarts {
  width: 50%;
}

@media screen and (max-width: 1205px) {
  .mainContent {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .mainContent {
    flex-direction: column-reverse;
    gap: 1rem;
  }
  .pageItem {
    width: 100%;
    height: auto;
  }
  .pageCarts {
    width: 100%;
    height: auto;
  }
  .checkoutHeader h1 {
    margin-top: 0;
  }
}
