header {
  height: 5rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  background: #fff;
  position: sticky;
  -webkit-position: sticky;
  top: 0;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1500px;
  width: 100%;
  height: inherit;
}

.navHelp {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media screen and (max-width: 1478px) {
  .navContainer {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
