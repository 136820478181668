.PaymentNavContainer {
  width: 100%;
  display: flex;
  gap: 30px;
}

.PaymentNavSection {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.PaymentNavSectionIcon {
  width: 53px;
  height: 53px;
  border-radius: 50%;
}

.PaymentNavSectionText {
  margin-left: 10px;
  margin-top: 5px;
}

@media screen and (max-width: 1024px) {
  .PaymentNavContainer {
    gap: 0px;
  }
}
