.logoStyle {
  width: 5rem;
  border-radius: 7px;
  z-index: 2;
}

.logoStyle:hover + .chooseNetwork {
  background: rgba(0, 0, 0, 0.3);
  width: 5rem;
  height: 2.5rem;
  position: absolute;
  margin-left: -4.4rem;
  border-radius: 7px;
  z-index: 2;
}

.controlLabel {
  display: flex;
  flex-direction: column;
}

.shimmerStyle {
  border-radius: 8px;
  position: absolute;
  margin-top: -3rem;
  z-index: 1;
}

.checkMarkStyle {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-top: -3.2rem;
  margin-left: 4.3rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 2px;
  transition: all 0.2s ease-out;
}

.sendOtpBtn {
  background: #d8e8ff;
  color: #1f6fde;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  height: 46px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  font-size: 16px;
}

.sendOtpBtn:hover {
  text-decoration: underline;
}

.errorContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.verifiedNumber {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1rem;
}

.verifiedNumberText {
  text-align: center;
  font-weight: 500;
  font-size: 17px;
}

.totalPriceContainer {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
}

.payBtn {
  height: 58px;
  font-size: 1.1rem;
  border-radius: 8px;
  line-height: 1.35;
  color: #fff;
  background: #1f6fde;
}

.payBtn:disabled {
  background: #cbcfd9;
}

@media screen and (max-width: 900px) {
  .totalPriceContainer {
    margin-bottom: 60px;
  }
}
