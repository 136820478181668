* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

body {
  font-size: 16px;
  line-height: 1.4;
}
button {
  text-transform: none !important;
  padding: 0 20px;
  max-width: 186px;
  cursor: pointer;
  border: none;
  outline: none;
}
.navigateBtn {
  background: #0f0f10 !important;
  border-radius: 30px !important;
  height: 3.313rem;
  width: 7.875rem;
  font-size: 1.2rem !important;
  text-align: center;
  color: #fff !important;
}
.navigateBtn:disabled {
  background: #cbcfd9 !important;
}

button:disabled {
  cursor: not-allowed;
}

.pageTitle {
  color: #0f0f10;
  font-size: clamp(1rem, 6vw, 1.3rem);
  height: 30px;
  margin-bottom: 0.4rem;
  width: 200px;
}

.pageSubTitle {
  color: #6f7689;
  margin-bottom: 0.4rem;
  width: 200px;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: none;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
