.FailedPaymentCard {
  position: relative;
  margin: auto;
  padding: 2rem;
  max-width: 620px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #db1818;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FailedPaymentCardTitle {
  font-weight: 550;
  font-size: 24px;
}

.FailedPaymentCardBody {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-left: 3.5rem;
}
.FailedPaymentCardHeader {
  display: flex;
  gap: 1rem;
  align-items: center;
}

@media (max-width: 620px) {
  .FailedPaymentCard {
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
  }
}

@media (max-width: 520px) {
  .FailedPaymentCardTitle {
    font-size: 4.1vw;
  }
}
@media (max-width: 345px) {
  .FailedPaymentCardTitle {
    font-size: 3.9vw;
  }
}
@media (max-width: 330px) {
  .FailedPaymentCardTitle {
    font-size: 3.5vw;
  }
}
