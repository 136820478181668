.SuccessPage {
  display: grid;
  place-items: center;
  height: 100vh;
}

.SuccessPageContainer {
  position: relative;

  padding: 2rem;
  max-width: 520px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #18a306;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.SuccessPageHeader {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.SuccessPageHeaderTitle {
  font-weight: 550;
  font-size: 25px;
}

.SuccessPageBody {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-left: 3rem;
}
@media screen and (max-width: 520px) {
  .SuccessPageContainer {
    width: auto;

    margin-left: 1rem;
    margin-right: 1rem;
  }
  .SuccessPageHeaderTitle {
    font-size: 4.1vw;
  }
}

@media screen and (max-width: 345px) {
  .SuccessPageHeaderTitle {
    font-size: 3.9vw;
  }
}

@media screen and (max-width: 330px) {
  .SuccessPageHeaderTitle {
    font-size: 3.5vw;
  }
}
