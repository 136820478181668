.SessionError {
  position: relative;
  margin: 10rem auto;
  padding: 2rem;
  max-width: 520px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #0f0f10;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (max-width: 520px) {
  .SessionError {
    margin-left: 1rem;
    margin-right: 1rem;
    width: auto;
    margin-bottom: 50%;
  }
}
