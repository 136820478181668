.radio {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-radius: 8px;
  height: 90px;
  margin-left: 0px;
  margin-top: 20px;
}

.radio:hover {
  background: #f2f3f7;
}
.radioCircle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  margin-right: 1rem;
  background: #f2f3f7;
}

.radio:hover .radioCircle {
  background: #cdcfd6;
}

.radioLabel {
  display: flex;
  justify-content: space-between;
  width: 140px;
  margin-left: 1rem;
}
