.InitPaymentModal {
  z-index: 16;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  position: relative;
  margin: 15rem auto;
  max-width: 550px;
  width: 100%;
  height: auto;
  border-radius: 16px;
  overflow-y: hidden;
  overflow-x: hidden;
  text-align: center;
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: #fff;
}

.InitPaymentModalContent {
  display: flex;
  flex-direction: column;
}

.InitPaymentModalSpinner {
  max-width: 4rem;
  width: 100%;
  align-self: center;
}

@media (max-width: 550px) {
  .InitPaymentModal {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .InitPaymentModalContent {
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    align-items: center;
  }
  .InitPaymentModalSpinner {
    max-width: 2rem;
  }
}

@media (max-width: 280px) {
  .InitPaymentModalContent {
    flex-direction: column;
  }
}

@media (max-height: 980px) {
  .InitPaymentModal {
    margin-top: 15vh;
  }
}
