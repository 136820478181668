
.container{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-image: url('../../Assets/Images/KidBB1.jpg');
    background-repeat: no-repeat;
    background-size: cover;


}

.pageContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    width:700px;
    height: 350px;
    padding: 5px;
    background: #fff;
    border-radius: 20px;
    border:0.2px solid #FEFBF4;
    /* box-shadow: 0px 3px 5px 6px rgba(224,221,221,0.75); */
/* -webkit-box-shadow: 0px 3px 5px 6px rgba(224,221,221,0.75); */
/* -moz-box-shadow: 0px 3px 5px 6px rgba(224,221,221,0.75); */
  }

  .close {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    background: #ccc;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    color:#9BA9D2;
    cursor: pointer;
  }


  .logoSection{
    display:flex;
    justify-content: center;
    gap:2rem;
  }

  .Logo {
    width:80px;
    height: 80px;
    margin-top: 0.5rem;
  }
  .Logo img {
    width:100%;
  }
  .logoNotes{
        display: flex;
        flex-direction: column;
        gap: 0.9rem;
  }

  .logoNotes h2{
    color:#242E4C
  }

  .logoNotes p {
    color:#70788C
  }

  .donateSection{
    display:flex;
    justify-content: center;
    gap:2rem;
    flex-direction: column;
    align-items: center;
  }

  .donateSection input{
    padding: 10px;
    border-radius: 10px;
    outline: none;
    border: 0.5px solid #ccc;
  }

  .donateSection button {
    padding: 10px;
    max-width: 120px;
    width:100% ;
    border-radius: 5px;
    background: #ec1a6c;
    color:#fff;

  }

  @media screen and (max-width: 1020px) {
    .container{
    background-image: url('../../Assets/Images/KidBB.jpg');

    }
}
  @media screen and (max-width: 768px) {
    .pageContainer{
      margin-left: 1rem;
      margin-right: 1rem;
      width: auto;
    }
  }

  @media screen and (max-width: 480px) {
    .pageContainer{
     height:auto;
    }
  }
