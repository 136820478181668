.carts {
  padding: 35px;
  background: #fff;
  border: 1px solid #cbcfd9;
  border-radius: 10px;
  margin-bottom: 2rem;
}
.cartsHeader {
  width: 100px;
  color: #0f0f10;
  height: 6px;
  font-size: clamp(1.3rem, 6vw, 1.7rem);
}

.cartsSubHeader {
  color: #6f7689;
  margin-top: 30px;
}

.cartShow {
  overflow-y: auto !important;
}

.cartShow .cartFlexParent {
  max-height: calc(100dvh - 450px) !important;
  transition: all 0.5s ease-in-out;
}

.cartFlexParent {
  transition: all 0.5s ease-in-out;
  max-height: 160px;
}

.cartDefault {
  overflow: hidden;
}
.cartsItem {
  height: 60px;
  width: 100%;
  font-size: clamp(1rem, 5vw, 1.15rem);
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  align-items: center;
  transition: all 10s ease-in-out;
}

.cartsTotal {
  display: flex;
  justify-content: space-between;
  font-size: clamp(1.1rem, 6vw, 1.3rem);
  margin-top: 1rem;
}

.cartsItemPrice {
  color: #0f0f10;
  font-weight: 550;
  margin-left: 3px;
}

.showCartsItems {
  display: block;
}
.hideCartsItems {
  display: none;
}

@media (max-width: 1024px) {
  .carts {
    padding: 15px;
  }
}
