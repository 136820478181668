.otpModalContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OtpModal {
  z-index: 16;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  max-width: 520px;
  width: 100%;
  height: auto;
  padding: 1rem 2.3rem;
  margin: 0.4rem 0;
  border-radius: 16px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.CloseModal {
  margin-left: 27rem;
  margin-bottom: -2.4rem;
}

.CloseModalIcon {
  rotate: 45deg;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.3rem;
  cursor: pointer;
}

.CloseModalIcon:hover {
  background: rgba(0, 0, 0, 0.1);
}

.OtpModalTitle {
  font-size: 18px;
  font-weight: bold;
}

.OtpInput {
  height: 60px;
  border: 1px solid #cbcfd9;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  outline: none;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}
.OtpInput::placeholder {
  font-size: 20px;
  text-align: center;
}

.ResendOTP {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  color: #1f6fde;
  font-weight: 550;
  font-size: 14px;
}

.VerifyOTP {
  width: 100%;
  max-width: 100% !important;
  color: #fff;
  height: 53px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  background: #1f6fde;
  border-radius: 8px;
  margin-bottom: 2rem;
}
.VerifyOTP:disabled {
  background: #cbcfd9;
}

.VerifyOTPLoader {
  max-width: 1.7rem;
  width: 1.5rem;
}
@media (max-width: 670px) {
  .CloseModal {
    margin-bottom: -2.4rem;
    margin-left: auto;
  }
  .OtpModalTitle {
    font-size: 16px;
  }
}

@media (max-width: 525px) {
  .OtpModal {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media (max-width: 1024px) and (max-height: 980px) {
  .OtpModal {
    margin-top: 15vh;
  }
}
@media (max-width: 670px) and (max-height: 980px) {
  .OtpModal {
    max-width: 100%;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 16px 16px 0 0;
  }
}
